import { BMI } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface BMIItemProps {
  patient: Patient;
  currentBMI: Metric<"body.bmi"> | undefined;
  previousBMI?: Metric<"body.bmi"> | undefined;
}

export function BMIItem({ patient, currentBMI, previousBMI }: BMIItemProps) {
  const age = useAge(patient);

  const currentScore = currentBMI?.unit["kg/m2"];
  const previousScore = previousBMI?.unit["kg/m2"];

  const summary = useMemo(() => {
    if (currentScore == null) {
      return;
    }

    return {
      risk: BMI.rangesFor({ age }).findRisk({ bmi: currentScore }),
    };
  }, [currentScore, age]);

  const differenceToPreviousValue = useMemo(() => {
    if (currentScore == null || previousScore == null) {
      return 0;
    }

    return Number((currentScore - previousScore).toFixed(1));
  }, [currentScore, previousScore]);

  return (
    <MetricStatus
      caption={<>BMI</>}
      summaryContent={
        summary ? <MetricStatusBadge risk={summary.risk} /> : "--"
      }
      differenceToPreviousValue={differenceToPreviousValue}
    >
      <ValueItem
        value={
          currentScore != null && isFinite(currentScore)
            ? currentScore.toFixed(1)
            : "--"
        }
      />
    </MetricStatus>
  );
}
