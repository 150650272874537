import { RenderOptions, toCanvas } from "@bwip-js/browser";
import { useEffect, useRef } from "react";

interface BarcodeProps {
  text: string; // The text to encode in the barcode
  barHeight?: number;
  displayValue?: boolean;
  barcodeType?: RenderOptions["bcid"];
}

export function Barcode({
  text,
  barHeight = 36,
  displayValue = true,
  barcodeType = "code128",
}: BarcodeProps) {
  const svgRef = useRef<HTMLCanvasElement>(null);

  const toPixels = 2.835; /* Convert 72 dpi used in bwip to pixels, sort of... */

  useEffect(() => {
    if (svgRef.current) {
      const opts = {
        bcid: barcodeType,
        text,
        scale: 2,
        height: barHeight / toPixels,
        includetext: displayValue,
        barcolor: "#262C2E",
        textsize: 7,
      } as RenderOptions;
      if (barcodeType === "datamatrix") {
        opts.width = barHeight / toPixels;
        opts.includetext = false;
      }
      toCanvas(svgRef.current, opts);
    }
  }, [barHeight, barcodeType, displayValue, text]);

  return <canvas ref={svgRef} />;
}
