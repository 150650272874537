import { APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { useMemo } from "react";
import { PatientAge } from "render/fragments/patient/PatientAge";
import { LocaleLanguage } from "render/ui/format/LocaleLanguage";
import { SexDefinition } from "render/ui/format/SexDefinition";
import styles from "./styles.module.sass";

interface DetailsProps {
  onboarding: APITypesV2.QuestionnaireResponse | undefined;
  patient: Patient | undefined;
}

export function Details({ patient, onboarding }: DetailsProps) {
  const answers = useMemo(() => {
    if (onboarding) {
      return convertQuestionnaire(onboarding);
    }
  }, [onboarding]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.metricTitle}>Age</div>
        <div className={styles.data}>
          <div>
            {patient && (
              <>
                <PatientAge patient={patient} /> years old
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.metricTitle}>Sex</div>
        <div className={styles.data}>
          {patient && <SexDefinition sex={patient.sex} />}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.metricTitle}>Language</div>
        <div className={styles.data}>
          {patient?.preferredLanguage ? (
            <LocaleLanguage locale={patient.preferredLanguage} />
          ) : (
            <>&mdash;</>
          )}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.medications,
        })}
      >
        <div className={styles.metricTitle}>Medications</div>
        <div className={styles.data}>{answers?.medications}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.allergies,
        })}
      >
        <div className={styles.metricTitle}>Allergies</div>
        <div className={styles.data}>{answers?.allergies}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.diet,
        })}
      >
        <div className={styles.metricTitle}>Diet</div>
        <div className={styles.data}>{answers?.diet}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.cardioExercise,
        })}
      >
        <div className={styles.metricTitle}>Cardio exercise</div>
        <div className={styles.data}>{answers?.cardioExercise}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.strengthExercise,
        })}
      >
        <div className={styles.metricTitle}>Strength exercise</div>
        <div className={styles.data}>{answers?.strengthExercise}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.alcohol,
        })}
      >
        <div className={styles.metricTitle}>Alcohol</div>
        <div className={styles.data}>{answers?.alcohol}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.smoking,
        })}
      >
        <div className={styles.metricTitle}>Smoking</div>
        <div className={styles.data}>
          {answers?.smokingDailyCigarettes ?? answers?.smoking}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.snus,
        })}
      >
        <div className={styles.metricTitle}>Snus</div>
        <div className={styles.data}>{answers?.snus}</div>
      </div>
    </>
  );
}
