import { isDistinct } from "lib/isDistinct";
import { Lesion, byLinkDate, isPreferredLink } from "lib/lesion";
import { useMemo } from "react";
import { DetailsGraph } from "./components/DetailsGraph";
import { LesionHeroImage } from "./components/LesionHeroImage";
import styles from "./styles.module.sass";

interface LesionMacroClassificationProps {
  selected: Lesion | undefined;
}

export function LesionMacroClassification({
  selected,
}: LesionMacroClassificationProps) {
  const evolutions = useMemo(() => {
    return selected?.links
      .filter(isPreferredLink)
      .toSorted(byLinkDate("desc"))
      .filter(isDistinct((link) => link.scan));
  }, [selected]);

  const latest = evolutions?.at(0)?.annotation;

  return (
    <div className={styles.LesionMacroClassification}>
      <div className={styles.hero}>
        <div className={styles.image}>
          {latest && <LesionHeroImage annotation={latest} />}
        </div>
      </div>
      <div className={styles.details}>
        <DetailsGraph
          bounds={{ min: 0, max: 1 }}
          header={<>Asymmetry</>}
          labels={{ from: "Symmetric", to: "Asymmetric" }}
          value={latest?.classification.scoreFeaturesAsymmetry ?? 0}
        />

        <DetailsGraph
          bounds={{ min: 0, max: 1 }}
          header={<>Borders</>}
          labels={{ from: "Even", to: "Uneven" }}
          value={latest?.classification.scoreFeaturesBorders ?? 0}
        />

        <DetailsGraph
          bounds={{ min: 0, max: 1 }}
          header={<>Colour</>}
          labels={{ from: "Uniform", to: "Varying" }}
          value={latest?.classification.scoreFeaturesColor ?? 0}
        />

        <DetailsGraph
          bounds={{ min: 0, max: 10 }}
          header={<>Diameter</>}
          labels={{ from: "1mm", to: "10mm+" }}
          value={latest?.classification.size ?? 0}
        />
      </div>
    </div>
  );
}
