import { Country, getCountryFromVisit, getLatestVisit } from "lib/visit/visit";
import { useMemo } from "react";
import { useVisits } from "render/hooks/api/useVisits";
import { InvestigationalUseOnly } from "./InvestigationalUseOnly";
import { UKCAModal } from "./UKCAModal";

interface NonCEFallbackProps {
  name: string;
  patientId: string;
}

export function NonCEFallback({ name, patientId }: NonCEFallbackProps) {
  const visits = useVisits(patientId);
  const country = useMemo(() => {
    if (!visits) {
      return undefined;
    }
    const latestVisit = getLatestVisit(visits);
    if (!latestVisit) {
      return Country.GB;
    }
    return getCountryFromVisit(latestVisit);
  }, [visits]);

  if (country === Country.GB) {
    return <UKCAModal name={name} />;
  } else {
    return <InvestigationalUseOnly name={name} />;
  }
}
