import { APITypesV2 } from "@cur8/api-client";
import { HabitualStyle } from "@cur8/measurements";
import { createResolver } from "./resolve";

export type ConvertedQuestionnaire = ReturnType<typeof convertQuestionnaire>;

export function convertQuestionnaire(
  questionnaireResponse: APITypesV2.QuestionnaireResponse
) {
  const { findAnswer, readValue } = createResolver(questionnaireResponse);

  const v1 = {
    exerciseHoursPerWeek: readValue("weeklyExerciseHours"),
    snusCansPerWeek: readValue("snus.weeklyCans"),
    medications: readValue("medication"),
    healthConditionsFamily: readValue("healthConditionsFamily"),
  };

  const isV1 = questionnaireResponse.questionnaireType === "onboarding/1";
  const smokingStyle = findAnswer("smoking.style");
  const isSmoker =
    smokingStyle == null
      ? undefined
      : smokingStyle === (isV1 ? HabitualStyle.Currently : "Yes");

  return {
    v1,
    isV1,
    isSmoker,
    medications: readValue("medications.type"),
    allergies: readValue("allergies.list"),
    diet: readValue("diet"),
    cardioExercise: readValue("weeklyCardioExerciseHours"),
    strengthExercise: readValue("weeklyStrengthExerciseHours"),
    alcohol: readValue("alcohol.weeklyDrinks"),
    smoking: readValue("smoking.style"),
    smokingDailyCigarettes: readValue("smoking.dailyCigarettes"),
    snus: readValue("snus.frequency"),
    additionalWorries: readValue("additionalWorries"),
    healthConditions: readValue("healthConditions"),
    cardioConditions: readValue("cardioConditions"),
    cardiovascular: readValue("cardiovascular"),
    skinCancer: readValue("skinCancer"),
    diabetes: readValue("diabetes"),
    relevantConditions: readValue("relevantConditions"),
  };
}
