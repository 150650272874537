import { Patient } from "@cur8/rich-entity";
import {
  HeartAgeAPIPayload,
  ParsedHeartAgeAPIResponse,
  toParsedResponse,
  toPayload,
} from "lib/heart-age";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useAge } from "render/hooks/patient/useAge";
import { useReporting } from "render/hooks/useReporting";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";

const EMPTY: ParsedHeartAgeAPIResponse = {
  input: undefined,
  score: undefined,
  error: undefined,
};

export function useHeartAge(patient: Patient) {
  const { metrics, questionnaire } = usePatientData();
  const api = useAPIClient().measurement;
  const age = useAge(patient);
  const sex = patient.sex;
  const patientId = patient.patientId;

  const { handleError } = useReporting();
  const appInsights = useAppInsights();

  const [data, setData] = useState<ParsedHeartAgeAPIResponse>(EMPTY);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const {
    body: { height, weight },
    bloodwork: { hba1c, cholesterolHDL },
    cardio: {
      brachialPressure: {
        left: brachialPressureLeft,
        right: brachialPressureRight,
      },
    },
  } = metrics;

  const payload: HeartAgeAPIPayload | undefined = useMemo(() => {
    if (!age || !sex || !questionnaire.data) {
      return;
    }

    return toPayload({
      age,
      sex,
      questionnaire: questionnaire.data,
      metrics: {
        body: { height, weight },
        bloodwork: { hba1c, cholesterolHDL },
        cardio: {
          brachialPressure: {
            left: brachialPressureLeft,
            right: brachialPressureRight,
          },
        },
      },
    });
  }, [
    age,
    sex,
    questionnaire,
    height,
    weight,
    hba1c,
    cholesterolHDL,
    brachialPressureLeft,
    brachialPressureRight,
  ]);

  useEffect(() => {
    if (!payload) {
      return;
    }

    const request = api.predictHeartAge(payload);

    setIsLoading(true);

    request.result
      .then((response) => {
        setError(undefined);
        setData(toParsedResponse(response));

        appInsights.trackEvent({
          name: "doc-ui-dashboard-heart-age",
          properties: {
            patientId,
            ...response,
          },
        });
      })
      .catch((err) => {
        handleError(err);
        setError(err instanceof Error ? err.message : String(err));
        setData(EMPTY);
      })
      .finally(() => setIsLoading(false));

    return () => {
      request.abandon();
    };
  }, [api, appInsights, handleError, patientId, payload]);

  return {
    data,
    isLoading,
    error,
  };
}
