import Factory from "./assets/factory.svg?react";
import MD from "./assets/MD.svg?react";
import NekoLogo from "./assets/Neko-logo.svg?react";
import RTFM from "./assets/read_manual.png";
import UKCA from "./assets/UKCA.png";
import Warning from "./assets/warning.svg?react";
import styles from "./styles.module.sass";

interface UKCAModalProps {
  name: string;
}

export function UKCAModal({ name }: UKCAModalProps) {
  return (
    <div className={styles.BorderBox}>
      <div className={styles.header}>
        <div className={styles.ukcaHeader}>
          <NekoLogo />
          <div className={styles.iconGap}>
            <MD />
            <span>
              {name}
              <br />
              Assessment Tool
            </span>
          </div>
        </div>
        <img src={UKCA} alt="UKCA" className={styles.ukcaLogo} />
      </div>
      <div className={styles.hr}></div>
      <div className={styles.iconBarcode}>
        <Factory className={styles.factory} />
        <div className={styles.address}>
          <b>Company</b>
          <br />
          Neko Health AB
          <br />
          Svärdvägen 19
          <br />
          182 33 Danderyd
          <br />
          Sweden
          <br />
        </div>
        <div className={styles.address}>
          <b>UK Responsible Person</b>
          <br />
          Neko Health UK Ltd
          <br />
          7 Savoy Court
          <br />
          London
          <br />
          United Kingdom
          <br />
          WC2R 0EX
          <br />
        </div>
      </div>
      <div className={styles.hr}></div>
      <div className={styles.iconGap}>
        <img src={RTFM} alt="Manual" className={styles.manual} />
        <Warning />
      </div>
      <div className={styles.hr}></div>
      <div>Software version: {process.env.REACT_APP_VERSION}</div>
      <p>Made in Sweden</p>
    </div>
  );
}
