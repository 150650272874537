import { ConvertedQuestionnaire } from "lib/questionnaire/convert";

export function getFamilyHealthConditions(answers?: ConvertedQuestionnaire) {
  const { cardiovascular, skinCancer, diabetes, relevantConditions } =
    answers ?? {};

  return [
    isYes(cardiovascular) && "Cardiovascular disease",
    isYes(skinCancer) && "Skin cancer",
    isYes(diabetes) && "Diabetes",
    relevantConditions,
  ]
    .filter(Boolean)
    .join(", ");
}

function isYes(answer: string | undefined) {
  return answer === "Yes";
}
