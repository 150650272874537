import NekoLogo from "./assets/Neko-logo.svg?react";
import styles from "./styles.module.sass";

interface InvestigationalUseOnlyProps {
  name: string;
}

export function InvestigationalUseOnly({ name }: InvestigationalUseOnlyProps) {
  return (
    <div className={styles.BorderBox}>
      <div className={styles.header}>
        <NekoLogo />
      </div>
      <div className={styles.header}>
        <h2>{name}</h2>
        <h3>Investigational Use Only (IUO)</h3>
      </div>
      <div className={styles.hr}></div>
      <div>Software version: {process.env.REACT_APP_VERSION}</div>
      <p>Made in Sweden</p>
    </div>
  );
}
