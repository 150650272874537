import { Box } from "@cur8/rich-entity";
import {
  clampBox,
  fetchLesionImage,
  overscan,
} from "lib/api/resolvers/annotation";
import { easeOutCubic } from "lib/ease";
import { silenceAbort } from "lib/error";
import { LesionAnnotation, drawMask, findOutline } from "lib/lesion";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "render/hooks/useBlobImage";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import { Typography } from "render/ui/presentation/Typography";
import { LesionOutline } from "../LesionOutline";
import styles from "./styles.module.scss";

const NO_OFFSET = {
  x: 0,
  y: 0,
};

function Detail({
  label,
  value,
  unit,
  precision = 0,
}: {
  label: ReactNode;
  value: number | undefined | null;
  unit?: string;
  precision?: number;
}) {
  return (
    <div className={styles.Detail}>
      <div className={styles.label}>
        <Typography variant="label-m">{label}</Typography>
      </div>
      <div className={styles.data}>
        <Typography as="span" variant="numeral-m" className={styles.value}>
          {value == null && <>--</>}
          {value != null && (
            <RampUpNumber
              value={value}
              duration={4}
              precision={precision}
              ease={easeOutCubic}
            />
          )}
        </Typography>
        &ensp;
        <Typography as="span" variant="body-m" className={styles.unit}>
          {unit}
        </Typography>
      </div>
    </div>
  );
}

interface XRayProps {
  annotation: LesionAnnotation;
}

export function XRay({ annotation }: XRayProps) {
  const [motif, setMotif] = useState<{ blob: Blob; crop: Box }>();
  const [offset, setOffset] = useState(NO_OFFSET);

  const blob = motif?.blob;
  const crop = motif?.crop;

  const api = useAPIClient();

  const outline = useMemo(() => {
    const SIZE = 100;
    const INNER = 100;

    const mask = drawMask(annotation);

    const polygon = findOutline(mask);

    const minX = Math.min(...polygon.map((p) => p.x));
    const minY = Math.min(...polygon.map((p) => p.y));

    const normalized = polygon.map((p) => {
      return {
        x: p.x - minX,
        y: p.y - minY,
      };
    });

    const maxX = Math.max(...normalized.map((p) => p.x));
    const maxY = Math.max(...normalized.map((p) => p.y));

    const scale = Math.min(INNER / maxX, INNER / maxY);

    const scaled = normalized.map((p) => {
      return {
        x: p.x * scale,
        y: p.y * scale,
      };
    });

    const scaledX = Math.max(...scaled.map((p) => p.x));
    const scaledY = Math.max(...scaled.map((p) => p.y));

    const offsetX = SIZE / 2 - INNER / 2 + (INNER - scaledX) / 2;
    const offsetY = SIZE / 2 - INNER / 2 + (INNER - scaledY) / 2;

    const offsetted = scaled.map((p) => {
      return {
        x: p.x + offsetX,
        y: p.y + offsetY,
      };
    });

    if (offsetted.length > 0) {
      offsetted.push(offsetted[0]);
    }

    return { polygon: offsetted, scale };
  }, [annotation]);

  useEffect(() => {
    if (!annotation) {
      return;
    }

    const crop = overscan(annotation.data.rect, 4);
    const res = { w: Math.min(338, crop.w), h: Math.min(338, crop.h) };
    const request = fetchLesionImage(api, annotation, crop, res);
    request?.result
      .then((image) => {
        const { w, h } = image.meta.original.size;

        const bounds = new Box(0, 0, w, h);

        setMotif({ blob: image.blob, crop: clampBox(crop, bounds) });
      })
      .catch(silenceAbort);
  }, [api, annotation]);

  const image = useBlobImage(blob);

  const rect = annotation.data.rect;

  useEffect(() => {
    if (!crop || !image) {
      return;
    }

    const center = {
      x: rect.x + rect.w / 2,
      y: rect.y + rect.h / 2,
    };

    const offset = {
      x: (center.x - crop.x) / crop.w - 0.5,
      y: (center.y - crop.y) / crop.h - 0.5,
    };

    setOffset(offset);

    const timer = setTimeout(setOffset, 3400, NO_OFFSET);

    return () => {
      clearTimeout(timer);
    };
  }, [image, crop, rect]);

  // Don't render anything until image available to ensure animation
  // timings from CSS is honored
  if (!image) {
    return null;
  }

  const diameter = annotation?.classification.size;
  const circ = diameter ? diameter * Math.PI : undefined;
  const xyz = annotation.classification.xyz;

  return (
    <div className={styles.XRay} key={annotation.id}>
      <svg width="394" height="394" viewBox="0 0 394 394" fill="none">
        <g className="boundary">
          <rect
            x="0.5"
            y="0.5"
            width="393"
            height="393"
            rx="3.5"
            stroke="#C5D9DD"
          />
          <path
            d="M0.5 12.5L0.5 4C0.5 2.067 2.067 0.5 4 0.5L12.5 0.5"
            stroke="#496970"
          />
          <path
            d="M393.5 12.5L393.5 4C393.5 2.067 391.933 0.5 390 0.5L381.5 0.5"
            stroke="#496970"
          />
          <path
            d="M0.5 381.5L0.5 390C0.5 391.933 2.067 393.5 4 393.5L12.5 393.5"
            stroke="#496970"
          />
          <path
            d="M393.5 381.5L393.5 390C393.5 391.933 391.933 393.5 390 393.5L381.5 393.5"
            stroke="#496970"
          />
        </g>
      </svg>

      <div className={styles.image}>
        <img src={image.src} alt="Lesion" />
      </div>

      <svg width="394" height="394" viewBox="0 0 394 394" fill="none">
        <g stroke="#E3E5E5" opacity="0.5">
          <g className={styles.verticalGrid}>
            <path d="M28.1428 0V394" />
            <path d="M56.2856 0V394" />
            <path d="M84.4285 0V394" />
            <path d="M112.572 0V394" />
            <path d="M140.714 0V394" />
            <path d="M168.857 0V394" />
            <path d="M197 0V394" />
            <path d="M225.143 0V394" />
            <path d="M253.286 0V394" />
            <path d="M281.428 0V394" />
            <path d="M309.571 0V394" />
            <path d="M337.714 0V394" />
            <path d="M365.857 0V394" />
          </g>
          <g className={styles.horizontalGrid}>
            <path d="M394 28.1426L0 28.1426" />
            <path d="M394 56.2861L0 56.2861" />
            <path d="M394 84.4287L0 84.4287" />
            <path d="M394 112.571L0 112.571" />
            <path d="M394 140.714L0 140.714" />
            <path d="M394 168.857L0 168.857" />
            <path d="M394 197L0 197" />
            <path d="M394 225.143L0 225.143" />
            <path d="M394 253.286L0 253.286" />
            <path d="M394 281.429L0 281.429" />
            <path d="M394 309.571L0 309.571" />
            <path d="M394 337.714L0 337.714" />
            <path d="M394 365.857L0 365.857" />
          </g>
        </g>
      </svg>

      <svg width="394" height="394" viewBox="0 0 394 394" fill="none">
        <circle
          className={styles.encirculation}
          cx="197"
          cy="197"
          r="196.5"
          stroke="#C5D9DD"
          strokeDasharray="2 2"
        />

        <rect
          className={styles.outerBounds}
          x="113"
          y="113"
          width="168"
          height="168"
          rx="2"
          stroke="#C5D9DD"
          strokeDasharray="2 2"
        />
      </svg>

      <div
        className={styles.offset}
        style={{
          transform: `translate(${offset.x * 100}%, ${offset.y * 100}%)`,
        }}
      >
        <svg
          width="394"
          height="394"
          viewBox="0 0 394 394"
          fill="none"
          className={styles.outline}
        >
          <g className={styles.cross} stroke="#C5D9DD" strokeDasharray="2 2">
            <path d="M197 0L197 394" />
            <path d="M394 197L0 197" />
          </g>

          <g className={styles.crosshair} stroke="#008BB7">
            <path d="M203 197L199 197" />
            <path d="M197 203L197 199" />
            <path d="M195 197H191" />
            <path d="M197 195L197 191" />
          </g>

          <g className="outline-inner-bounds">
            <rect
              x="141"
              y="141"
              width="112"
              height="112"
              rx="2"
              stroke="#C5D9DD"
            />
            <path
              d="M253 149L253 143C253 141.895 252.105 141 251 141L245 141"
              stroke="#496970"
            />
            <path
              d="M253 245L253 251C253 252.105 252.105 253 251 253L245 253"
              stroke="#496970"
            />
            <path
              d="M141 149L141 143C141 141.895 141.895 141 143 141L149 141"
              stroke="#496970"
            />
            <path
              d="M141 245L141 251C141 252.105 141.895 253 143 253L149 253"
              stroke="#496970"
            />
          </g>

          <svg x="141" y="141" width="112" height="112">
            {outline && <LesionOutline polygon={outline.polygon} />}
          </svg>
        </svg>
      </div>

      <div className={styles.overlays}>
        <div className={styles.location}>
          <ul>
            <li>X:{xyz?.[0].toFixed(3)}</li>
            <li>Y:{xyz?.[1].toFixed(3)}</li>
            <li>Z:{xyz?.[2].toFixed(3)}</li>
          </ul>
        </div>

        <div className={styles.diameter}>
          <Detail
            label={<>Circumference</>}
            value={circ}
            unit="mm"
            precision={1}
          />
        </div>
      </div>
    </div>
  );
}
