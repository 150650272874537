import { Length } from "@cur8/measurements";
import { LengthValue } from "../components/LengthValue";
import { MeasurementToggle } from "../components/MeasurementToggle";
import styles from "./styles.module.sass";

interface CalfProps {
  circumference: Length;
}

export function Calf({ circumference }: CalfProps) {
  return (
    <div className={styles.Calf}>
      <MeasurementToggle anchor="right">
        <LengthValue
          value={<>{(circumference.meters * 100).toFixed(0)}</>}
          unit="cm"
        />
      </MeasurementToggle>
    </div>
  );
}
