import { useCallback, useEffect, useState } from "react";
import { useRecording } from "render/hooks/api/useRecording";
import { CEMarking } from "./CEMarking";
import { NonCEFallback } from "./NonCEFallback";
import styles from "./styles.module.sass";

export interface UDIComponents {
  deviceIdentifier: string;
  productionIdentifier: {
    lotNumber?: string;
    serialNumber?: string;
    expirationDate?: string;
    manufacturingDate?: string;
    ref?: string; // REF is first 6 chars of serialNumber
  };
}

function parseUDI(udi: string): UDIComponents {
  const diPattern = /^\((01)\)([A-Za-z0-9_-]{14})/; // Device Identifier (14 chars)
  const piPatterns = {
    lotNumber: /\(10\)([A-Za-z0-9_-]+)/, // Lot Number
    serialNumber: /\(21\)([A-Za-z0-9_-]+)/, // Serial Number
    expirationDate: /\(17\)(\d{6})/, // Expiration Date (YYMMDD format)
    manufacturingDate: /\(11\)(\d{6})/, // Manufacturing Date (YYMMDD format)
  };

  const diMatch = udi.match(diPattern);
  if (!diMatch) {
    throw new Error(
      "Invalid UDI format: Missing or invalid Device Identifier."
    );
  }

  const deviceIdentifier = diMatch[2];
  const productionIdentifier: UDIComponents["productionIdentifier"] = {};

  // Parse PI parts
  for (const [key, pattern] of Object.entries(piPatterns)) {
    const match = udi.match(pattern);
    if (match) {
      productionIdentifier[key as keyof UDIComponents["productionIdentifier"]] =
        match[1];
    }
  }
  if (productionIdentifier.serialNumber) {
    productionIdentifier.ref = productionIdentifier.serialNumber.substring(
      0,
      6
    );
  }

  return { deviceIdentifier, productionIdentifier };
}

interface RegulatoryModalProps {
  name: string;
  deviceId: string;
  patientId: string;
  recordingId: string;
}

export function RegulatoryModal({
  name,
  deviceId,
  patientId,
  recordingId,
}: RegulatoryModalProps) {
  const [showBox, setShowBox] = useState<boolean>(false);
  const [udi, setUdi] = useState<UDIComponents>();
  const toggleBox = useCallback(() => {
    setShowBox((show) => !!!show);
  }, []);

  const recording = useRecording(deviceId, recordingId);

  useEffect(() => {
    if (recording && recording.metadata.cur8_udi) {
      try {
        const parsedUdi = parseUDI(recording.metadata.cur8_udi);
        setUdi(parsedUdi);
      } catch (e) {
        console.error(e);
      }
    }
  }, [recording]);

  return (
    <div className={styles.RegulatoryModal} data-show={showBox}>
      <div className={styles.infoBtn} onClick={toggleBox}>
        [About]
      </div>
      <div className={styles.ModalBg}>
        <div className={styles.Modal}>
          <h1>Legal & Regulatory</h1>

          {recording && udi ? (
            <CEMarking
              name={name}
              udi={udi}
              rawUdi={recording.metadata.cur8_udi}
            />
          ) : (
            <NonCEFallback name={name} patientId={patientId} />
          )}
          <div className={styles.backButton} onClick={toggleBox}>
            Back
          </div>
        </div>
      </div>
    </div>
  );
}
