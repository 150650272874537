export enum Flags {
  DoctorBrief = "92abe5bf-0f20-41a6-8047-412fd6044422",
  Cardio_2_0 = "zgh0tkTViI5s",
}

const params = new URLSearchParams(window.location.search);

export const FLAGS = new Set<Flags>(params.getAll("flags") as Flags[]);

/**
 * @usage Wrap the component you want to hide in prod like:
 *  `{hiddenInProd() && <NothingToSeeHere/>}`
 */
export function hiddenInProd() {
  const hn = window.location.hostname;
  if (hn === "doctor.cur8.co" || hn === "doctor.preprod.cur8.co") {
    return false;
  }
  return true;
}
