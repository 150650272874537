import { Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import HeartRythmIcon from "./assets/heart-rythm.svg?react";

interface HeartRythmItemProps {
  ecgDisc: Metric<"risk_assessment.ecg_discrepancy">[] | undefined;
}

export function HeartRythmItem({ ecgDisc }: HeartRythmItemProps) {
  const summary = useMemo(() => {
    const ecgReview = ecgDisc?.at(0)?.unit;
    if (ecgReview === true) {
      return { risk: Risk.Risk };
    }
    if (ecgReview === false) {
      return { risk: Risk.Normal };
    }
  }, [ecgDisc]);

  return (
    <MetricStatus
      caption={<>Heart rhythm</>}
      summaryContent={
        summary ? <MetricStatusBadge risk={summary.risk} /> : "--"
      }
    >
      <HeartRythmIcon />
    </MetricStatus>
  );
}
