import { APITypesV1 } from "@cur8/api-client";
import { RequestEnvelope } from "@cur8/api-client/dist/api/types";
import { Patient } from "@cur8/rich-entity";
import { toList } from "@pomle/react-flat-store";
import { MergedRecord } from "lib/doctor-scribe/types";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useMSAL } from "render/context/MSALContext";
import { useStore } from "render/context/StoreContext";
import { usePatientVisitSummaries } from "render/pages/PatientDetailPage/hooks/usePatientVisitSummaries";
import {
  compareSummaryRequest,
  latestSummaryRequest,
  questionRequest,
  suggestionRequest,
} from "./llmRequests";
import {
  addVisits,
  hasPreviousScan,
  latestMeasurementDaysAgo,
  latestOnly,
  latestVisitSummaryDaysAgo,
} from "./results/resultHelpers";
import { fetchResults } from "./results/results";
import { allValuesDefinedOrNull, toContent } from "./utils";

export type Brief = {
  latestSummary: string | undefined | null;
  compareSummary: string | undefined | null;
  suggestions: string | undefined | null;
  questions: string | undefined | null;
  generatedAt: DateTime | undefined;
};

const EMPTY_BRIEF: Brief = {
  latestSummary: undefined,
  compareSummary: undefined,
  suggestions: undefined,
  questions: undefined,
  generatedAt: undefined,
};

export function useBrief(patient: Patient, visitId: string, active: boolean) {
  const [results, setResults] = useState<MergedRecord>();

  const startTimeRef = useRef<DateTime>();
  const latestReqRef = useRef<RequestEnvelope<APITypesV1.OpenAIResponse>>();
  const compareReqRef = useRef<RequestEnvelope<APITypesV1.OpenAIResponse>>();
  const suggestionReqRef = useRef<RequestEnvelope<APITypesV1.OpenAIResponse>>();
  const questionReqRef = useRef<RequestEnvelope<APITypesV1.OpenAIResponse>>();

  const { auth } = useMSAL();
  const appInsights = useAppInsights();
  const api = useAPIClient();

  const { get, set } = useStore().brief.entries;
  const [brief, setBrief] = useState(
    get(patient.patientId).data || EMPTY_BRIEF
  );

  const { entries: visitEntries } = usePatientVisitSummaries(patient.patientId);

  const trackEvent = useCallback(
    (name: string) => {
      const time = startTimeRef.current
        ? DateTime.now()
            .diff(startTimeRef.current, "seconds")
            .toObject()
            .seconds?.toFixed(0)
        : 0;
      if (patient && auth && auth.account) {
        appInsights.trackEvent(
          { name },
          {
            userId: auth.account.homeAccountId,
            patient: patient.patientId,
            time,
          }
        );
      }
    },
    [appInsights, auth, patient]
  );

  const visits = useMemo(() => {
    let visits = toList(visitEntries);
    const measurementsDaysAgo = latestMeasurementDaysAgo(results);
    const summaryDaysAgo = latestVisitSummaryDaysAgo(visits);

    if (
      summaryDaysAgo &&
      measurementsDaysAgo &&
      summaryDaysAgo - measurementsDaysAgo < 7
    ) {
      console.warn(
        "Removing last VisitSummary since you are using pre-briefs after-the-fact... "
      );
      trackEvent("brief_afterTheFact");
      return visits.slice(1);
    } else {
      return visits;
    }
  }, [results, trackEvent, visitEntries]);

  // First summarize and compare with previous scans
  useEffect(() => {
    if (active === false) {
      return;
    }

    if (results && patient && visitEntries !== null) {
      // Summarize latest results
      if (!brief.latestSummary && !latestReqRef.current) {
        startTimeRef.current = DateTime.now();
        setBrief((p) => ({ ...p, generatedAt: startTimeRef.current }));
        trackEvent("brief_started");

        const latestOnlyResults = latestOnly(results);
        latestReqRef.current = api.nlp.OAIChat(
          latestSummaryRequest(latestOnlyResults)
        );
        latestReqRef.current.result
          .then((r) => {
            setBrief((p) => ({ ...p, latestSummary: toContent(r) }));
            latestReqRef.current = undefined;
            trackEvent("brief_latestSummaryGenerated");
          })
          .catch(console.error);
      }

      // Compare with previous scan
      if (!brief.compareSummary && !compareReqRef.current) {
        if (hasPreviousScan(results)) {
          const resultsWithSummaries = addVisits(results, visits);
          compareReqRef.current = api.nlp.OAIChat(
            compareSummaryRequest(resultsWithSummaries)
          );
          compareReqRef.current.result
            .then((r) => {
              setBrief((p) => ({ ...p, compareSummary: toContent(r) }));
              compareReqRef.current = undefined;
              trackEvent("brief_compareSummaryGenerated");
            })
            .catch(console.error);
        } else {
          setBrief((p) => ({ ...p, compareSummary: null }));
        }
      }
    }
  }, [
    api,
    patient,
    results,
    visitEntries,
    visits,
    trackEvent,
    brief.compareSummary,
    brief.latestSummary,
    active,
  ]);

  // Then generate suggested suggestions and questions
  useEffect(() => {
    if (active === false) {
      return;
    }
    if (patient && brief.latestSummary && brief.compareSummary !== undefined) {
      if (!brief.suggestions && !suggestionReqRef.current) {
        suggestionReqRef.current = api.nlp.OAIChat(
          suggestionRequest(brief.latestSummary, brief.compareSummary || "")
        );
        suggestionReqRef.current.result
          .then((r) => {
            setBrief((p) => ({ ...p, suggestions: toContent(r) }));
            suggestionReqRef.current = undefined;
            trackEvent("brief_suggestionsGenerated");
          })
          .catch(console.error);
      }

      if (!brief.questions && !questionReqRef.current) {
        questionReqRef.current = api.nlp.OAIChat(
          questionRequest(brief.latestSummary, brief.compareSummary || "")
        );
        questionReqRef.current.result
          .then((r) => {
            setBrief((p) => ({ ...p, questions: toContent(r) }));
            questionReqRef.current = undefined;
            trackEvent("brief_questionsGenerated");
          })
          .catch(console.error);
      }
    }
  }, [api, patient, trackEvent, brief, active]);

  function regenerateBrief() {
    setBrief(EMPTY_BRIEF);
  }

  function isGenerating() {
    return (
      latestReqRef.current !== undefined ||
      compareReqRef.current !== undefined ||
      suggestionReqRef.current !== undefined ||
      questionReqRef.current !== undefined
    );
  }

  useEffect(() => {
    if (active === false) {
      return;
    }
    fetchResults(api, patient, visitId).then(setResults);
  }, [active, api, patient, visitId]);

  useEffect(() => {
    if (active === false) {
      return;
    }
    if (
      brief.latestSummary ||
      brief.compareSummary !== undefined ||
      brief.suggestions ||
      brief.questions
    ) {
      set(patient.patientId, brief);
    }
    if (allValuesDefinedOrNull(brief)) {
      trackEvent("brief_allGenerated");
    }
  }, [brief, set, patient, trackEvent, active]);

  useEffect(() => {
    if (active === false) {
      return;
    }
    latestReqRef.current?.abandon();
    compareReqRef.current?.abandon();
    suggestionReqRef.current?.abandon();
    questionReqRef.current?.abandon();
  }, [active]);

  return { brief, results, visits, regenerateBrief, isGenerating };
}
