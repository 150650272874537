import { Patient, PulseWaveAssessment } from "@cur8/rich-entity";
import { isRecordingURI } from "lib/uri/guard";
import { RegulatoryModal } from "render/fragments/regulatory/RegulatoryModal";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { AssessmentSidebox } from "./components/AssessmentSidebox";
import { useCardioSignalsTimestamps } from "./hooks/useCardioSignalsTimestamps";
import { RangeHandler } from "./RangeHandler";
import styles from "./styles.module.sass";

interface PulseWavePageProps {
  patient: Patient;
  assessment: PulseWaveAssessment;
}

export function PulseWavePage({ patient, assessment }: PulseWavePageProps) {
  const scan = useImmutableScan(
    patient.patientId,
    assessment.scanId,
    assessment.scanVersion
  );

  const recUri = scan?.sourceUris.filter(isRecordingURI).at(0);

  const signals = useCardioSignalsTimestamps(scan);

  return (
    <div className={styles.PulseWavePage}>
      <AssessmentSidebox sex={patient.sex} />
      <div className={styles.plotArea}>
        {signals.loading ? (
          <div className={styles.loading}>
            <h1>Loading cardio signals...</h1>
          </div>
        ) : (
          <div className={styles.plotComponent}>
            <RangeHandler
              key={
                assessment.itemVersion
              } /* Needed to trigger re-instantiation when version changes */
              assessment={assessment}
              patient={patient}
              signals={signals}
            />
          </div>
        )}
      </div>
      {scan && recUri && (
        <RegulatoryModal
          name="Echo-1"
          deviceId={recUri.deviceId}
          patientId={patient.patientId}
          recordingId={recUri.recordingId}
        />
      )}
    </div>
  );
}
