import { Length } from "@cur8/measurements";
import { LengthValue } from "../components/LengthValue";
import { MeasurementToggle } from "../components/MeasurementToggle";
import styles from "./styles.module.sass";

interface ThoraxProps {
  circumference: Length;
}

export function Thorax({ circumference }: ThoraxProps) {
  return (
    <div className={styles.Thorax}>
      <MeasurementToggle anchor="left">
        <LengthValue
          value={<>{(circumference.meters * 100).toFixed(0)}</>}
          unit="cm"
        />
      </MeasurementToggle>
    </div>
  );
}
