import { convertQuestionnaire } from "lib/questionnaire/convert";
import { useMemo } from "react";
import { useABIScore } from "render/hooks/api/metrics/useABIScore";
import { useBMIScore } from "render/hooks/api/metrics/useBMIScore";
import { useScore2 } from "render/hooks/api/metrics/useScore2";
import { useTBIScore } from "render/hooks/api/metrics/useTBIScore";
import { usePatient } from "render/hooks/api/usePatient";
import { usePatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { useLiveTime } from "render/hooks/useLiveTime";
import { useQuestionnaire } from "../api/useQuestionnaire";

export function usePatientHealthData(patientId: string) {
  const now = useLiveTime("hour");

  const patient = usePatient(patientId);
  const metrics = usePatientMetrics(patientId);
  const questionnaire = useQuestionnaire({ patientId, visitId: undefined });
  const lifestyle = useMemo(() => {
    if (questionnaire.data) {
      return convertQuestionnaire(questionnaire.data);
    }
  }, [questionnaire.data]);

  const aggregates = {
    score2: useScore2({
      patient,
      date: now,
      brachial: metrics.cardio.brachialPressure,
      isSmoker: lifestyle?.isSmoker,
      nonHDL: metrics.bloodwork.nonHDL,
    }),

    bmi: useBMIScore(metrics.body),

    abi: {
      left: useABIScore("left", metrics.cardio),
      right: useABIScore("right", metrics.cardio),
    },

    tbi: useTBIScore(metrics.cardio),
  };

  return {
    patientId,
    patient,
    metrics,
    aggregates,
    lifestyle,
  };
}
