import { APITypesV1 } from "@cur8/api-client";
import { Visit, fromAPI } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

const attendedStatuses = [
  APITypesV1.VisitStatus.CheckedIn,
  APITypesV1.VisitStatus.Ongoing,
  APITypesV1.VisitStatus.Completed,
] as const;

export function useAttendedVisits(
  patientId: string | undefined,
  count: number
) {
  const { logError } = useReporting();
  const [visits, setVisits] = useState<Visit[]>();

  const api = useAPIClient();

  useEffect(() => {
    if (patientId == null) {
      return;
    }

    const requests = attendedStatuses.map((status) =>
      api.visit.getPatientVisits(
        { patientId },
        {
          order: APITypesV1.SortOrder.Desc,
          pageSize: count,
          status,
        }
      )
    );

    Promise.all(requests.map((req) => req.result))
      .then((requests) => {
        return requests.reduce<APITypesV1.Visit[]>((acc, request) => {
          acc.push(...request.items);
          return acc;
        }, []);
      })
      .then((visits) => {
        return visits.map(fromAPI.toVisit);
      })
      .then((visits) => {
        return visits.sort((a, b) => {
          return b.startTime.toMillis() - a.startTime.toMillis();
        });
      })
      .then(setVisits)
      .catch(logError)
      .catch(silenceAbort);

    return () => {
      requests.forEach((req) => req.abandon());
    };
  }, [api, count, patientId, logError]);

  const result = useMemo(() => {
    return visits?.slice(0, count);
  }, [visits, count]);

  return result;
}
