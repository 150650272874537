import { easeOutCubic } from "lib/ease";
import {
  DETECTED_PHYSICAL_ARTIFACT_MEDIAN,
  getSelected,
  hasScan,
  isDermaLink,
} from "lib/lesion";
import { useEffect, useMemo, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import { Typography } from "render/ui/presentation/Typography";
import MarkerIcon from "./assets/marker.svg?react";
import { DistributionDiagram } from "./components/DistributionDiagram";
import styles from "./styles.module.sass";

interface SkinSectionProps {
  active: boolean;
}

function valWithDefault<T>(val: T | undefined, placeholder: T) {
  return val ?? placeholder;
}

export function SkinSection({ active }: SkinSectionProps) {
  const {
    skin: { lesions },
    scans,
  } = usePatientData();

  const { ui } = useDashboardContext();

  const [activated, setActivated] = useState(false);

  useEffect(() => {
    if (active) {
      setTimeout(setActivated, 2000, true);
    }
  }, [active]);

  const latestSkinScan = scans?.skin.at(0);

  const latestLesions = useMemo(() => {
    if (!lesions || !latestSkinScan) {
      return;
    }

    return lesions.filter(hasScan(latestSkinScan));
  }, [latestSkinScan, lesions]);

  const selectedCount = useMemo(
    () => (lesions ? getSelected(lesions).length : undefined),
    [lesions]
  );

  const dermaCount = useMemo(() => {
    return lesions?.filter(({ links }) => {
      return links.find(isDermaLink);
    }).length;
  }, [lesions]);

  return (
    <div className={styles.SkinSection}>
      <Typography as="h2" variant="display-s" className={styles.header}>
        Skin
      </Typography>

      <hr />

      <div className={styles.markings}>
        <section>
          <header>
            <Typography as="span" variant="title-s">
              Detected markings
            </Typography>
          </header>

          <article>
            <MarkerIcon />

            <Typography variant="numeral-m">
              <RampUpNumber
                value={activated ? valWithDefault(latestLesions?.length, 0) : 0}
                duration={2}
                precision={0}
                ease={easeOutCubic}
              />
            </Typography>
          </article>
        </section>

        <section>
          <header>
            <Typography as="span" variant="title-s">
              Typical Neko member
            </Typography>
          </header>

          <article className={styles.typicalNo}>
            <Typography variant="numeral-m">
              <RampUpNumber
                value={
                  activated
                    ? latestLesions
                      ? DETECTED_PHYSICAL_ARTIFACT_MEDIAN
                      : 0
                    : 0
                }
                duration={2}
                precision={0}
                ease={easeOutCubic}
              />
            </Typography>
          </article>
        </section>

        <div
          onClick={() => {
            ui.set({ layer: Layer.LesionLibrary });
          }}
        >
          <DistributionDiagram
            count={activated ? latestLesions?.length ?? 0 : 0}
          />
        </div>

        <div>
          <DistributionDiagram
            count={
              activated
                ? latestLesions
                  ? DETECTED_PHYSICAL_ARTIFACT_MEDIAN
                  : 0
                : 0
            }
          />
        </div>
      </div>

      <div className={styles.findings}>
        <section>
          <header>
            <Typography as="span" variant="title-s">
              Selected
            </Typography>
          </header>

          <article className={styles.count}>
            <Typography variant="numeral-m">
              <RampUpNumber
                value={activated ? valWithDefault(selectedCount, 0) : 0}
                duration={2}
                precision={0}
                ease={easeOutCubic}
              />
            </Typography>
          </article>
        </section>
        <section>
          <header>
            <Typography as="span" variant="title-s">
              Derma
            </Typography>
          </header>
          <article className={styles.count}>
            <Typography variant="numeral-m">
              <RampUpNumber
                value={activated ? valWithDefault(dermaCount, 0) : 0}
                duration={2}
                precision={0}
                ease={easeOutCubic}
              />
            </Typography>
          </article>
        </section>
      </div>
    </div>
  );
}
