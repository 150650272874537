import CE from "./assets/CE.svg?react";
import Factory from "./assets/factory.svg?react";
import MD from "./assets/MD.svg?react";
import NekoLogo from "./assets/Neko-logo.svg?react";
import ReadManual from "./assets/read_manual.png";
import REF from "./assets/REF.svg?react";
import SN from "./assets/SN.svg?react";
import UDI from "./assets/UDI.svg?react";
import Warning from "./assets/warning.svg?react";
import { Barcode } from "./Barcode";
import { UDIComponents } from "./RegulatoryModal";
import styles from "./styles.module.sass";

interface CEMarkingProps {
  name: string;
  rawUdi: string;
  udi: UDIComponents | undefined;
}

export function CEMarking({ name, rawUdi, udi }: CEMarkingProps) {
  return (
    <div className={styles.BorderBox}>
      <div className={styles.header}>
        <NekoLogo />
        <CE />
      </div>
      <div className={styles.iconGap}>
        <MD />
        <span>
          {name}
          <br />
          Assessment Tool
        </span>
      </div>
      <div className={styles.hr}></div>
      <div>
        <div className={styles.iconGap}>
          <Factory />
          <span>Company</span>
        </div>
        <p>
          Neko Health AB
          <br />
          Svärdvägen 19, 182 33 Danderyd, Sweden
        </p>
      </div>
      <div className={styles.iconGap}>
        <img
          src={ReadManual}
          alt="Read manual"
          className={styles.readManualImg}
        />
        <Warning />
      </div>
      <div>Software version: {process.env.REACT_APP_VERSION}</div>
      <div className={styles.hr}></div>
      {udi !== undefined && (
        <>
          <div className={styles.iconBarcode}>
            <REF />
            <Barcode text={udi.productionIdentifier.ref!} barHeight={17} />
          </div>
          <div className={styles.iconBarcode}>
            <SN />
            <Barcode
              text={udi.productionIdentifier.serialNumber!}
              barHeight={17}
            />
          </div>
          <div className={styles.iconBarcode}>
            <UDI />
            <Barcode text={rawUdi} barcodeType="datamatrix" barHeight={42} />
            <span className={styles.udi}>
              (01){udi.deviceIdentifier}
              <br />
              (11){udi.productionIdentifier.manufacturingDate}
              <br />
              (21){udi.productionIdentifier.serialNumber}
              <br />
            </span>
          </div>
        </>
      )}
      <div className={styles.hr}></div>
      <p>Made in Sweden</p>
    </div>
  );
}
