import { HeartRate, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { RISK_COLORS } from "render/risk";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface HeartRateFloatProps {
  heartRate: Metric<"cardio.heart_rate">[];
  width?: number;
}

export function HeartRateFloat({
  heartRate,
  width = 120,
}: HeartRateFloatProps) {
  const latest = heartRate.at(0);

  const risk = useMemo(() => {
    if (latest) {
      return HeartRate.rangesFor().findRisk(latest.unit);
    }
  }, [latest]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label={<>Heart rate</>}
      value={
        <ValueItem
          value={latest ? latest.unit.bpm.toFixed(0) : "--"}
          unit="beats / min"
          size="huge"
        />
      }
      color={color}
      width={width}
    />
  );
}
